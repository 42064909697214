
import { PropType } from "@vue/runtime-core";
import { Options, Vue } from "vue-class-component";

interface Item {
  id: string;
  label?: string;
  text?: string;
  path?: string;
  children: Item[];
}

@Options({
  props: {
    /**
     * The item to show
     */
    item: { type: Object as PropType<Item>, required: true },
  },
  data() {
    return {
      expanded: false,
    };
  },
  watch: {
    $route(to) {
      // If link is selected emit link-selected to parent
      if (to.path === this.item.path) {
        this.$emit("link-selected");
      }
    },
  },
  methods: {
    /**
     * Toggle dropdown expanded
     */
    toggleExpanded() {
      this.expanded = !this.expanded;
    },
    /**
     * Expand dropdown
     */
    setExpanded() {
      this.expanded = true;
    },
  },
})
export default class LuLeftMenuItem extends Vue {}
