
import { PropType } from "@vue/runtime-core";
import { Options, Vue } from "vue-class-component";
import LuLeftMenu from "@/components/Lu/LuLeftMenu.vue";
import Tooltip from "@/components/Tooltip.vue";

interface Item {
  id: string;
  label?: string;
  text?: string;
  path?: string;
  children: Item[];
}

@Options({
  components: {
    LuLeftMenu,
    Tooltip,
  },
  props: {
    /**
     * Items to show in left menu
     */
    leftmenu: { type: Array as PropType<Item[]>, default: null },
    /**
     * Email to page manager
     */
    pageManagerMail: { type: String as PropType<string>, default: "" },
    /**
     * Page manager notice
     */
    pageManagerNotice: { type: String as PropType<string>, default: "" },
    /**
     * When the page was last updated
     */
    lastUpdated: { type: String as PropType<string>, default: "" },
    /**
     * Compact layout
     */
    compact: Boolean as PropType<boolean>,
  },
  computed: {
    toTopText() {
      return `${this.$t("shared.scroll-to-top")} - `;
    },
  },
  methods: {
    /**
     * Called from a "scroll" event listener to hide or show the scroll to top button
     *  depending on the distance from the top that the page is scrolled
     */
    handleScroll() {
      const toTopButton = document.getElementById("toTopButton");
      const distanceFromTop = 150;

      if (
        document.documentElement.scrollTop > distanceFromTop || // Chrome, Firefox, IE & Opera
        document.body.scrollTop > distanceFromTop // Safari
      ) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        toTopButton!.style.display = "block";
      } else {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        toTopButton!.style.display = "none";
      }
    },
    scrollToTop() {
      document.documentElement.scrollTop = 0; // Chrome, Firefox, IE & Opera
      document.body.scrollTop = 0; // Safari
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
})
export default class LuMain extends Vue {}
