
import LuDropdownItem from "@/components/Lu/LuDropdownItem.vue";
import { PropType } from "@vue/runtime-core";
import { Options, Vue } from "vue-class-component";
import type { IMenuItem } from "@/interfaces/IMenuItem";

@Options({
  components: {
    LuDropdownItem,
  },
  props: {
    /**
     * The item to show
     */
    item: { type: Object as PropType<IMenuItem>, required: true },
  },
})
export default class LuNavbarItem extends Vue {}
