import type { IState } from "@/interfaces/IState";
import type { IUser } from "@/interfaces/IUser";

export default {
  setBadge(state: IState, payload: { id: string; badge: string }): void {
    state.menuBadges[payload.id] = payload.badge;
  },
  setCurrentUser(state: IState, user: IUser): void {
    state.currentUser = user;
  },
  showSpinner(state: IState, show: boolean): void {
    state.showSpinner = show;
  },
};
