
import LuLeftMenuItem from "@/components/Lu/LuLeftMenuItem.vue";
import { PropType } from "@vue/runtime-core";
import { Options, Vue } from "vue-class-component";

interface Item {
  id: string;
  label?: string;
  text?: string;
  path?: string;
  children: Item[];
}

@Options({
  components: {
    LuLeftMenuItem,
  },
  props: {
    /**
     * Array of items to show
     */
    menu: { type: Array as PropType<Item[]>, required: true },
  },
})
export default class LuMain extends Vue {}
