/* istanbul ignore file */
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faGlobe,
  faChevronLeft,
  faChevronRight,
  faEllipsisH,
  faChevronUp,
  faChevronDown,
  faCaretDown,
  faCaretUp,
  faArrowCircleLeft,
  faArrowCircleRight,
  faArrowCircleUp,
  faBars,
  faTimes,
  faTrashAlt,
  faSearch,
  faPlusCircle,
  faMinusCircle,
  faArrowLeft,
  faCalendar,
  faLock,
  faExclamation,
  faSignInAlt,
  faSignOutAlt,
  faExternalLink,
  faChevronCircleRight,
  faCircleNotch,
  faUndo,
  faFileExcel,
  faInfoCircle,
  faWrench,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  faGlobe,
  faChevronLeft,
  faChevronRight,
  faEllipsisH,
  faChevronUp,
  faChevronDown,
  faCaretDown,
  faCaretUp,
  faArrowCircleLeft,
  faArrowCircleRight,
  faArrowCircleUp,
  faBars,
  faTimes,
  faTrashAlt,
  faSearch,
  faPlusCircle,
  faMinusCircle,
  faArrowLeft,
  faCalendar,
  faLock,
  faExclamation,
  faSignInAlt,
  faSignOutAlt,
  faExternalLink,
  faChevronCircleRight,
  faCircleNotch,
  faUndo,
  faFileExcel,
  faInfoCircle,
  faWrench
);

export default FontAwesomeIcon;
