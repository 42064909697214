
import { Options, Vue } from "vue-class-component";
import { PropType } from "@vue/runtime-core";
import Badge from "@/components/Badge.vue";
import type { IMenuItem } from "@/interfaces/IMenuItem";

@Options({
  props: {
    /**
     * The item to show
     */
    item: { type: Object as PropType<IMenuItem>, required: true },
  },
  data() {
    return {
      /**
       * If the item is expanded
       */
      expanded: false,
    };
  },
  watch: {
    /**
     * Gets called when route/location changes
     */
    $route(to) {
      // If link is selected emit link-selected to parent
      if (to.path === this.item.path) {
        this.$emit("link-selected");
      }
    },
  },
  methods: {
    /**
     * Toggle visibility
     */
    toggleExpanded() {
      this.expanded = !this.expanded;
    },
    /**
     * Gets called when a child item is selected
     */
    childSelected() {
      this.expanded = true;
      // Resend event upwards
      this.$emit("link-selected");
    },
  },

  components: {
    Badge,
  },
})
export default class LuNavMobileItem extends Vue {}
