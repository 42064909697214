import type { IState } from "@/interfaces/IState";

const state: IState = {
  menuBadges: {},
  currentUser: undefined,
  showSpinner: false,
  isSubmitted: false,
};

export default state;
