
import { Options, Vue } from "vue-class-component";
import LuDropdownItem from "@/components/Lu/LuDropdownItem.vue";
import { PropType } from "@vue/runtime-core";
import Badge from "@/components/Badge.vue";
import type { IMenuItem } from "@/interfaces/IMenuItem";
// import NoteTakerService from "@/services/notetaker-service";
// import { NoteTakerStatusCount } from "@/interfaces/api";

interface Item {
  id: string;
  label?: string;
  text?: string;
  path?: string;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  children: Item[];
}

@Options({
  components: {
    LuDropdownItem,
    Badge,
  },

  data: () => ({
    noteTakerStatusCount: null,

    // Fill with getter from store
    menu: {},
  }),

  props: {
    /**
     * The item to show
     */
    item: { type: Object as PropType<IMenuItem>, required: true },
    /**
     * If this is the last item
     */
    lastItem: Boolean as PropType<boolean>,
  },

  // methods: {
  //   /**
  //    * Fills noteTakerStatusCount with the name and count of statuses
  //    */
  //   getNoteTakerStatusCount(): void {
  //     NoteTakerService.getNoteTakerStatusCount().then((statuses) => {
  //       this.noteTakerStatusCount = statuses;
  //     });
  //   },

  //   /**
  //    * Returns the count of the specific status
  //    */
  //   getStatusCount(status: string): number {
  //     return this.noteTakerStatusCount.find(
  //       (sc: NoteTakerStatusCount) => sc.status.toLowerCase() === status
  //     ).count;
  //   },
  //   mounted() {
  //     this.getNoteTakerStatusCount();
  //   },
  // },

  computed: {
    active() {
      /* Check if any child is active */
      let childActive = false;
      if (this.item.children && this.$refs.children) {
        childActive = this.$refs.children
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .map((child: any) => child.active)
          .includes(true);
      }

      return (
        childActive ||
        this.$route.path === this.item?.path ||
        this.$route.name === this.item?.name
      );
    },
  },
})
export default class LuTopMenu extends Vue {}
