<template>
  <span class="tooltip-container">
    <slot />
    <span class="tooltip" :style="{ '--bgcolor': bgColor, color: color }">
      <span class="text">{{ text }}</span>
    </span>
  </span>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    bgColor: {
      type: String,
      required: false,
      default: "grey",
    },
    color: {
      type: String,
      required: false,
      default: "white",
    },
  },
};
</script>

<style scoped>
.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-container:hover .tooltip {
  opacity: 1;
  display: block;
}

.tooltip {
  text-align: center;
  padding: 10px;
  border-radius: 5px;

  width: 170px;
  bottom: 100%;
  left: 50%;
  margin-left: -90px;

  opacity: 0;
  display: none;
  transition: opacity 0.5s;

  position: absolute;
  z-index: 5;
  background-color: var(--bgcolor);
}

.text::after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: var(--bgcolor) transparent transparent transparent;
}
</style>
