
import { Options, Vue } from "vue-class-component";

@Options({
  methods: {
    /**
     * Gets called when person clicks the backdrop.
     */
    onClick(event: Event): void {
      // Make sure the click was on the backdrop
      if (event.target === this.$refs.backdrop) {
        this.$emit("clickBackdrop");
      }
    },
  },
})
export default class Backdrop extends Vue {}
