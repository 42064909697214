import jwt from "jsonwebtoken";
import LocalstorageService from "@/services/localstorage-service";
import store from "@/store";
import { Claims, Constants, SessionStorageKeys } from "@/constants/constants";
import type { IUser } from "@/interfaces/IUser";
import type { IEntitlement } from "@/interfaces/IEntitlement";
import SessionStorageService from "@/services/sessionstorage-service";

export type IEntitlementsGuard =
  | IEntitlement
  | [IEntitlementsGuard, "and" | "or", IEntitlementsGuard];

export interface JwtPayload extends jwt.JwtPayload {
  [Claims.Entitlement]: IEntitlement | IEntitlement[];
  [Claims.FirstName]: string;
  [Claims.LastName]: string;
  [Claims.LastName]: string;
  [Claims.LuEduPersonPrimaryId]: string;
  [Claims.PersonalId]: string;
}

class AuthenticationService {
  protected readonly baseApiUrl = process.env.VUE_APP_BASE_API_URL;
  protected readonly localStorageJWTKey = "jwtToken";

  private getUserFromToken(token: string): IUser {
    const payload = jwt.decode(token) as JwtPayload;
    const entitlements = payload[Claims.Entitlement];
    const user: IUser = {
      firstName: payload[Claims.FirstName],
      lastName: payload[Claims.LastName],
      personalId: payload[Claims.PersonalId],
      email: payload[Claims.Email],
      entitlements:
        typeof entitlements === "string" ? [entitlements] : entitlements,
      luEduPersonPrimaryId: payload[Claims.LuEduPersonPrimaryId],
      checkEntitlement: this.hasEntitlements.bind(this),
    };
    return user;
  }

  /**
   * Authenticate user against IDP and return jwt token
   *
   * @returns {Promise<string>} A promise with a jwt token
   */
  public authenticate(): Promise<string> {
    const url = `${this.baseApiUrl}authorize`;
    return new Promise((resolve, reject) => {
      const popupParams =
        "scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no";
      const popup = window.open(url, "Login", popupParams);
      if (!popup) {
        return reject();
      }
      const tokenCallback = (event: MessageEvent<string>) => {
        const token = event.data;
        if (typeof token !== "string") {
          return;
        }
        popup.close();
        window.removeEventListener("message", tokenCallback);
        LocalstorageService.setItem(this.localStorageJWTKey, token);
        this.populateStore();
        resolve(token);
      };
      window.addEventListener("message", tokenCallback);
    });
  }

  /**
   * Verifies that the token has not expired
   *
   * @returns {boolean} A boolean representing if the token has not expired
   */
  private verifyTokenExpiry(token: string): boolean {
    const { exp } = jwt.decode(token) as JwtPayload;
    if (!exp || Date.now() >= exp * 1000) {
      return false;
    }
    return true;
  }

  /**
   * Fetches user authentication data
   *
   * @returns {Promise<string>} A promise with jwt token
   */
  public getJWTToken(): string | undefined {
    const token = LocalstorageService.getItem<string>(this.localStorageJWTKey);
    if (!token || !this.verifyTokenExpiry(token)) {
      return undefined;
    }
    return token;
  }

  public populateStore() {
    const token = this.getJWTToken();
    if (token) {
      store.commit("setCurrentUser", this.getUserFromToken(token));
    }
  }

  public isLoggedIn(): boolean {
    return !!this.getJWTToken();
  }

  public hasEntitlements(entitlements: IEntitlementsGuard): boolean {
    const token = this.getJWTToken();
    if (!token) {
      return false;
    }
    const user = this.getUserFromToken(token);
    return this.validateEntitlements(user.entitlements, entitlements);
  }

  private validateEntitlements(
    userEntitlements: IEntitlement[] | undefined,
    entitlements: IEntitlementsGuard
  ): boolean {
    if (!userEntitlements) {
      return false;
    }
    if (userEntitlements.includes("SuperAdmin")) {
      return true;
    }
    if (typeof entitlements === "string") {
      return userEntitlements.includes(entitlements);
    }
    const entitlement1Valid = this.validateEntitlements(
      userEntitlements,
      entitlements[0]
    );
    const entitlement2Valid = this.validateEntitlements(
      userEntitlements,
      entitlements[2]
    );
    if (entitlements[1] === "or") {
      return entitlement1Valid || entitlement2Valid;
    }
    return entitlement1Valid && entitlement2Valid;
  }

  public logout(): void {
    LocalstorageService.deleteItem(this.localStorageJWTKey);
    SessionStorageService.deleteItem(Constants.EntryUrl);
    SessionStorageService.deleteItem(SessionStorageKeys.IsMirroringUser);
    SessionStorageService.deleteItem(SessionStorageKeys.MirroredUser);
    SessionStorageService.deleteItem(SessionStorageKeys.MirrorUserSearchTerm);
    store.commit("setCurrentUser", undefined);
    const url = `${this.baseApiUrl}Shibboleth.sso/Logout`;
    window.location.href = url;
  }
}

export default new AuthenticationService();
