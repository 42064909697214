
import { computed } from "vue";
import { Options, Vue } from "vue-class-component";
import LuHeader from "@/components/Lu/LuHeader.vue";
import BreadCrumbs from "@/components/BreadCrumbs.vue";
import LuSpinner from "@/components/Lu/LuSpinner.vue";
import Backdrop from "@/components/Backdrop.vue";
import LuMain from "@/components/Lu/LuMain.vue";
import LuFooter from "@/components/Lu/LuFooter.vue";
import AuthenticationService from "@/services/authentication-service";
import LocalStorageService from "@/services/localstorage-service";
import i18n from "@/utilities/i18n";
import { Constants } from "@/constants/constants";
import { RouteLocation } from "vue-router";

const {
  global: { locale, t },
} = i18n;

@Options({
  components: {
    LuHeader,
    LuMain,
    LuFooter,
    BreadCrumbs,
    LuSpinner,
    Backdrop,
  },
  data: () => ({
    /**
     * The title for the current page
     */
    headerTitle: "",
    /**
     * Boolean value representing if current route path starts with "administrator"
     */
    isAdminPath: false,
    /**
     * The footer data for the current page
     */
    footerContent: [],
    /**
     * Boolean value representing if current route path is "/maintenance"
     */
    isMaintenancePath: false,
  }),
  computed: {
    /**
     * Only returns menu if current route path starts with "administrator"
     * or current user is "SuperAdmin"
     */
    getMenu() {
      if (
        this.$store.state.currentUser !== undefined &&
        this.$store.state.currentUser.entitlements.includes("Employee")
      ) {
        return this.$store.getters.getMenu;
      }

      return this.isAdminPath ? this.$store.getters.getMenu : [];
    },
    /**
     * Hides footer if current route path starts with "administrator"
     */
    showFooter() {
      return !this.isAdminPath;
    },
    /**
     * The currently chosen locale
     */
    locale() {
      return this.$i18n.locale;
    },
    /**
     * If the spinner should be shown
     */
    showSpinner() {
      return this.$store.state.showSpinner;
    },
    /**
     * If the mobile menu should be shown
     */
    showMobileMenu() {
      return this.isAdminPath;
    },
    /**
     * If the mobile menu should be shown
     */
    showLogin() {
      return !this.isMaintenancePath;
    },
  },
  methods: {
    /**
     * Sets the page title
     */
    setPageTitle(): void {
      (document.activeElement as HTMLElement).blur();
      const appName = computed(() => t("shared.app-name")).value;
      const titleKey = this.$route.meta.titleKey;
      const pageTitle = titleKey ? t(titleKey) : "";
      document.title = `${pageTitle} - ${appName}` || appName;
      this.headerTitle = `${pageTitle}` || appName;
      document.getElementsByTagName("html")[0].lang = locale.value;
      const footerContent = this.$route.meta.footerContent;
      if (footerContent) {
        this.footerContent = footerContent;
      }
    },
  },
  watch: {
    /**
     * Gets called when route/location changes
     */
    $route(to: RouteLocation): void {
      this.setPageTitle();
      const path = to.fullPath;
      this.isAdminPath = path.startsWith("/administrator");
      this.isMaintenancePath = path === "/maintenance";

      if ("lang" in to.query) {
        // It doesn't break if the lang is not supported.
        // Can be good to leave it if we support more languages in the future.
        this.$i18n.locale = to.query.lang;
      }
    },
    /**
     * Gets called when locale changes
     */
    locale(): void {
      this.setPageTitle();
    },
  },
  mounted() {
    AuthenticationService.populateStore();

    if (LocalStorageService.keyExists(Constants.Language)) {
      this.$i18n.locale = LocalStorageService.getItem(Constants.Language);
    }
  },
})
export default class App extends Vue {}
