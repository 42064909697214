
import { Options, Vue } from "vue-class-component";
import { PropType } from "@vue/runtime-core";

@Options({
  props: {
    /**
     * The item to show in dropdown
     */
    item: {
      type: Object as PropType<{ label?: string; text?: string; url?: string }>,
      required: true,
    },
  },
  computed: {
    /**
     * If the route is the currently showing page
     */
    active() {
      return (
        this.$route.path === this.item?.path ||
        this.$route.name === this.item?.name
      );
    },
  },
})
export default class LuDropdownItem extends Vue {}
