import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "float-right ml-2" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_LuLeftMenuItem = _resolveComponent("LuLeftMenuItem", true)!

  return (_ctx.item.children && (_ctx.item.path || _ctx.item.name))
    ? (_openBlock(), _createElementBlock("li", _hoisted_1, [
        _createVNode(_component_router_link, {
          to: {
        ...(_ctx.item.path ? { path: _ctx.item.path } : {}),
        ...(_ctx.item.name ? { name: _ctx.item.name } : {}),
      },
          class: _normalizeClass(["nav-link collapse", [_ctx.expanded ? 'show' : 'collapsed']]),
          onClick: _ctx.toggleExpanded
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_font_awesome_icon, {
                icon: _ctx.expanded ? 'chevron-down' : 'chevron-right'
              }, null, 8, ["icon"])
            ]),
            _createTextVNode(" " + _toDisplayString(_ctx.item.label ? _ctx.$t(_ctx.item.label) : _ctx.item.text), 1)
          ]),
          _: 1
        }, 8, ["to", "class", "onClick"]),
        _createElementVNode("ul", {
          class: _normalizeClass(["nav-accordion collapse", [_ctx.expanded ? 'show' : '']])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.children, (subItem) => {
            return (_openBlock(), _createBlock(_component_LuLeftMenuItem, {
              key: subItem.id,
              item: subItem,
              onLinkSelected: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setExpanded()))
            }, null, 8, ["item"]))
          }), 128))
        ], 2)
      ]))
    : (_ctx.item.path || _ctx.item.name)
      ? (_openBlock(), _createElementBlock("li", _hoisted_3, [
          _createVNode(_component_router_link, {
            to: {
        ...(_ctx.item.path ? { path: _ctx.item.path } : {}),
        ...(_ctx.item.name ? { name: _ctx.item.name } : {}),
      },
            class: "nav-link"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.item.label ? _ctx.$t(_ctx.item.label) : _ctx.item.text), 1)
            ]),
            _: 1
          }, 8, ["to"])
        ]))
      : (_openBlock(), _createElementBlock("li", _hoisted_4, [
          _createElementVNode("a", {
            href: _ctx.item.url,
            class: "nav-link"
          }, _toDisplayString(_ctx.item.label ? _ctx.$t(_ctx.item.label) : _ctx.item.text), 9, _hoisted_5)
        ]))
}