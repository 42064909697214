import JsonHelper from "@/helpers/json-helper";

class LocalStorageService {
  setItem(key: string, data: unknown): boolean {
    try {
      if (typeof data === "string") {
        localStorage.setItem(key, data);
      } else {
        localStorage.setItem(key, JSON.stringify(data));
      }
      return true;
    } catch (e) {
      console.error("LocalStorageService.setItem ~ error", e);
      return false;
    }
  }

  getItem<T>(key: string): T | undefined {
    if (key) {
      const storageData = localStorage.getItem(key);
      if (storageData) {
        try {
          if (JsonHelper.isValidJSON(storageData)) {
            const data = JSON.parse(storageData);
            return data;
          } else {
            return storageData as unknown as T;
          }
        } catch (e) {
          console.error("LocalStorageService.getItem ~ error", e);
          return undefined;
        }
      }
    }
    return undefined;
  }

  deleteItem(key: string) {
    localStorage.removeItem(key);
  }

  keyExists(key: string): boolean {
    return Object.prototype.hasOwnProperty.call(localStorage, key);
  }
}

export default new LocalStorageService();
