import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6eb62e40"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "footer" }
const _hoisted_2 = { class: "container py-2" }
const _hoisted_3 = { class: "footer-sections" }
const _hoisted_4 = { class: "logo-container" }
const _hoisted_5 = ["src", "alt"]
const _hoisted_6 = ["src", "alt"]
const _hoisted_7 = {
  key: 0,
  class: "footer-links"
}
const _hoisted_8 = { class: "link-column" }
const _hoisted_9 = { class: "column-title" }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = {
  key: 1,
  class: "footer-link"
}
const _hoisted_12 = ["href", "innerHTML"]
const _hoisted_13 = ["href"]
const _hoisted_14 = { class: "icon" }
const _hoisted_15 = ["href", "innerHTML"]
const _hoisted_16 = ["innerHTML"]
const _hoisted_17 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_ctx.locale === 'sv')
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: require('@/assets/Lunds_universitet_white.svg'),
                alt: _ctx.logoAlt,
                class: "footer-logo"
              }, null, 8, _hoisted_5))
            : _createCommentVNode("", true),
          (_ctx.locale === 'en')
            ? (_openBlock(), _createElementBlock("img", {
                key: 1,
                src: require('@/assets/Lunds_university_white.svg'),
                alt: _ctx.logoAlt,
                class: "footer-logo"
              }, null, 8, _hoisted_6))
            : _createCommentVNode("", true)
        ]),
        (_ctx.footerContent)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.footerContent, (column) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: column.columnNumber
                }, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.translate(column.title)), 1),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(column.links, (link) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "links",
                        key: link.url
                      }, [
                        (link.leadingText)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 0,
                              innerHTML: _ctx.translate(link.leadingText) + ' '
                            }, null, 8, _hoisted_10))
                          : _createCommentVNode("", true),
                        (link.linkType === 'external')
                          ? (_openBlock(), _createElementBlock("span", _hoisted_11, [
                              _createElementVNode("a", {
                                href: _ctx.translate(link.url),
                                target: "_blank",
                                rel: "noopener noreferrer",
                                class: "footer-link",
                                innerHTML: _ctx.translate(link.linkLabel)
                              }, null, 8, _hoisted_12),
                              _createElementVNode("a", {
                                href: _ctx.translate(link.url),
                                target: "_blank",
                                rel: "noopener noreferrer",
                                class: "footer-link"
                              }, [
                                _createElementVNode("span", _hoisted_14, [
                                  _createVNode(_component_font_awesome_icon, { icon: "external-link" })
                                ])
                              ], 8, _hoisted_13)
                            ]))
                          : _createCommentVNode("", true),
                        (link.linkType === 'email')
                          ? (_openBlock(), _createElementBlock("a", {
                              key: 2,
                              href: `mailto:${_ctx.translate(link.url)}`,
                              class: "footer-link",
                              innerHTML: _ctx.translate(link.linkLabel)
                            }, null, 8, _hoisted_15))
                          : _createCommentVNode("", true),
                        (link.linkType === 'internal')
                          ? (_openBlock(), _createBlock(_component_router_link, {
                              key: 3,
                              to: link.url,
                              class: "footer-link"
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("span", {
                                  innerHTML: _ctx.translate(link.linkLabel)
                                }, null, 8, _hoisted_16)
                              ]),
                              _: 2
                            }, 1032, ["to"]))
                          : _createCommentVNode("", true),
                        (link.trailingText)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 4,
                              innerHTML: _ctx.translate(link.trailingText) + ' '
                            }, null, 8, _hoisted_17))
                          : _createCommentVNode("", true)
                      ]))
                    }), 128))
                  ])
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}