import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-33b0b094"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_LuDropdownItem = _resolveComponent("LuDropdownItem")!
  const _component_Badge = _resolveComponent("Badge")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.item.children)
      ? (_openBlock(), _createElementBlock("li", {
          key: 0,
          class: _normalizeClass(["nav-item dropdown dropdown-hover d-flex", { active: _ctx.active }])
        }, [
          (_ctx.item.path || _ctx.item.name)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: {
        ...(_ctx.item.path ? { path: _ctx.item.path } : {}),
        ...(_ctx.item.name ? { name: _ctx.item.name } : {}),
      },
                class: "nav-link text-nowrap dropdown-toggle pr-0 cursor-pointer",
                "aria-haspopup": "true"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.item.label ? _ctx.$t(_ctx.item.label) : _ctx.item.text), 1)
                ]),
                _: 1
              }, 8, ["to"]))
            : (_openBlock(), _createElementBlock("a", {
                key: 1,
                href: _ctx.item.url,
                class: "nav-link text-nowrap dropdown-toggle pr-0 cursor-pointer",
                "aria-haspopup": "true",
                style: {"cursor":"pointer"},
                tabindex: "0"
              }, _toDisplayString(_ctx.item.label ? _ctx.$t(_ctx.item.label) : _ctx.item.text), 9, _hoisted_1)),
          _createElementVNode("div", {
            class: _normalizeClass(["dropdown-menu font-size-base", [_ctx.lastItem ? 'dropdown-menu-right' : '']])
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.children, (subMenuItem) => {
              return (_openBlock(), _createBlock(_component_LuDropdownItem, {
                key: subMenuItem.id,
                item: subMenuItem,
                ref_for: true,
                ref: "children"
              }, null, 8, ["item"]))
            }), 128))
          ], 2)
        ], 2))
      : (_openBlock(), _createElementBlock("li", {
          key: 1,
          class: _normalizeClass(["nav-item d-flex", { active: _ctx.active }])
        }, [
          (_ctx.item.path || _ctx.item.name)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: {
        ...(_ctx.item.path ? { path: _ctx.item.path } : {}),
        ...(_ctx.item.name ? { name: _ctx.item.name } : {}),
      },
                class: "nav-link text-nowrap pr-0",
                "aria-haspopup": "true"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.item.label ? _ctx.$t(_ctx.item.label) : _ctx.item.text), 1)
                ]),
                _: 1
              }, 8, ["to"]))
            : (_openBlock(), _createElementBlock("a", {
                key: 1,
                href: _ctx.item.url,
                class: "nav-link text-nowrap pr-0",
                "aria-haspopup": "true",
                style: {"cursor":"pointer"}
              }, _toDisplayString(_ctx.item.label ? _ctx.$t(_ctx.item.label) : _ctx.item.text), 9, _hoisted_2))
        ], 2)),
    _createVNode(_component_Badge, {
      class: "badge mb-4",
      rounded: true,
      backgroundColor: "crimson"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.item.badge), 1)
      ]),
      _: 1
    })
  ], 64))
}