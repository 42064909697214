
import { Options, Vue } from "vue-class-component";
import { PropType } from "@vue/runtime-core";
import Modal from "@/components/Modal.vue";
import LuNavMobileItem from "@/components/Lu/LuNavMobileItem.vue";
import LuNavMobileItemChangeLanguage from "@/components/Lu/LuNavMobileItemChangeLanguage.vue";
import type { IMenuItem } from "@/interfaces/IMenuItem";
import LuHeaderAuthButtons from "@/components/Lu/LuHeaderAuthButtons.vue";

@Options({
  components: {
    Modal,
    LuNavMobileItem,
    LuNavMobileItemChangeLanguage,
    LuHeaderAuthButtons,
  },
  props: {
    /**
     * The menu to show
     */
    menu: { type: Array as PropType<IMenuItem[]>, required: true },
  },
  methods: {
    /**
     * Open the navigation
     */
    open() {
      this.$refs.modal.open();
    },
    /**
     * Close the navigation
     */
    close() {
      this.$refs.modal.close();
    },
  },
})
export default class LuNavMobile extends Vue {}
