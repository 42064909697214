class PageContentIds {
  static readonly EmployeeStart = 1;
  static readonly StudentStart = 2;
}

export class Claims {
  static readonly Entitlement =
    "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";
  static readonly FirstName =
    "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname";
  static readonly LastName =
    "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname";
  static readonly Email =
    "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress";
  static readonly LuEduPersonPrimaryId = "luEduPersonPrimaryId";
  static readonly PersonalId = "personalId";
}
export class Constants {
  static readonly DateFormat = "YYYY-MM-DD";
  static readonly DateTimeFormat = "YYYY-MM-DD HH:mm";
  static readonly NoteTakingFunctionId = 1;
  static readonly Language = "language";
  static readonly PageContentIds = PageContentIds;
  static readonly Claims = Claims;
  static readonly SearchSubmissionDelayMilliseconds = 1000;
  static readonly CourseTitleTruncationLength = 40;
  static readonly StudentHomePageTypeId = 1;
  static readonly EmployeeHomePageTypeId = 2;
  static readonly NoteSupportFilesRelativeUrl = "files/note-support/";
  static readonly NoteSupportTermsAndConditionsPdfSv =
    "Överenskommelse om uppdrag som anteckningsstöd.pdf";
  static readonly NoteSupportTermsAndConditionsPdfEn =
    "Agreement on assignment as note-taking support.pdf";
  static readonly NotAvailable = "unavailable";
  static readonly MinDateValue = "0001-01-01";

  /************* localstorage keys *************/
  static readonly FilteredNoteTakerIds = "filteredNoteTakerIds";
  static readonly FilteredStudentIds = "filteredStudentIds";
  static readonly SortedCourseOccurrenceIds = "sortedCourseOccurrenceIds";
  static readonly NoteTakerTableUserPagingData = "noteTakerTableUserPagingData";
  static readonly StudentTableUserPagingData = "studentTableUserPagingData";
  static readonly CourseOccurrenceTableUserPagingData =
    "courseOccurrenceTableUserPagingData";
  static readonly NoteTakerSupportCourseTableUserPagingData =
    "noteTakerSupportCourseTableUserPagingData";
  static readonly StudentSupportCourseTableUserPagingData =
    "studentSupportCourseTableUserPagingData";
  static readonly EntryUrl = "entryUrl";
}

export class SessionStorageKeys {
  static readonly PaymentResponse = "paymentResponse";
  static readonly MirroredUser = "mirroredUser";
  static readonly MirrorUserSearchTerm = "mirrorUserSearchTerm";
  static readonly IsMirroringUser = "isMirroringUser";
  static readonly Coordinators = "coordinators";
}
